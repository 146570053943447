import { PublicClientApplication } from '@azure/msal-browser'
import { getLoginUrl, overviewGetLoginUrl } from '~/api'
import {
  FETCHING_HEALTHCHECK_TENANT_INTERVAL,
  FETCHING_HEALTHCHECK_TENANT_INTERVAL_KEY
} from '~/pages/HealthCheck/HealthCheck'
import { isOverviewPage } from './helpers'
const msalConfig = {
  auth: {
    clientId: import.meta.env.VITE_MICROSOFT_CLIENT_ID,
    authority: 'https://login.microsoftonline.com/common/'
  }
}

const msalInstance = new PublicClientApplication(msalConfig)

const msalLoginPopup = async (scopes = ['User.Read']) => {
  return await msalInstance.loginPopup({
    scopes,
    prompt: 'select_account'
  })
}

export default msalLoginPopup

export const grantPermissionRedirect = async (tenantUdid, redirectUrl) => {
  const state = Math.random().toString().substring(2)
  const api = isOverviewPage() ? overviewGetLoginUrl : getLoginUrl
  const {
    data: { url }
  } = await api(tenantUdid)

  window.location.href = `${url}&redirect_uri=${redirectUrl}&state=${state}`
  localStorage.setItem(
    FETCHING_HEALTHCHECK_TENANT_INTERVAL_KEY,
    FETCHING_HEALTHCHECK_TENANT_INTERVAL
  ) // Continious fetching permission state
  localStorage.setItem('GRANT_PERMISSION_STATE', state)

  return state
}
