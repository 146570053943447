// eslint-disable-next-line import/no-anonymous-default-export
export default {
  translation: {
    general: {
      english: 'English',
      dutch: 'Dutch',
      searchFor: 'Search for ',
      serials_or_State: 'serials or state',
      serials_or_importStatus: 'serials or status',
      serials_or_AadDeviceId: 'serials or AadDeviceId',
      serials_or_preProvisioningId: 'serials or Pre Provisioning Id',
      name_or_domain_name: 'Name or Domain Name',
      schoolName: 'school name',
      dashboard: 'Dashboard',
      yourAccountAreAllSet: 'Your account are all set!',
      hasIntuneLicenceValid:
        'The connection with the tenant is lost, please grant the app permission',
      grantAppPermission: 'Grant App Permission',
      tenantOverview: 'Tenant Overview',
      registerSessionOverview: 'Register Device Sessions Overview',
      registrationDetail: 'Registration Detail',
      healthCheck: 'Health Check',
      ggHealthCheck: 'Google Domain Health Check',
      syncDevice: 'Sync Devices',
      syncingDevice: 'Syncing Devices',
      overview: 'Overview',
      inviteAzureTenant: 'Invite Azure Tenant',
      inviteGoogleDomain: 'Invite Google Domain',
      users: 'Users',
      uploadCSV: 'Upload CSV or TXT file',
      bulkDeleteDevice: 'Bulk delete devices',
      missingPermission: "It appears you don't have the necessary permissions to view this page!",
      checkZteToken: 'Click on this button to check ZTE Token valid',
      successfullUpdate: 'Successfully updated.',
      searchRange: 'Select date range',
      clearFilter: 'Clear Filter',
      noOption: 'No options'
    },
    adminMenu: {
      dashboard: 'Dashboard',
      tenants: 'Tenants',
      devices: 'Devices',
      devices_register_session: 'Device Register Sessions',
      administrator: {
        administrator: 'Administrator',
        inviteAzureTenants: 'Invite Azure Tenants',
        inviteGooleDomain: 'Invite Google Domain',
        users: 'Users',
        schoolUsers: {
          registrations: 'Registrations',
          schoolUsers: 'School Users',
          disable: 'Disable School Users'
        },
        admins: {
          admins: 'Admins',
          disable: 'Disable Admins'
        },
        companies: 'Companies',
        roles: 'Roles',
        settings: 'Settings'
      }
    },
    userMenu: {
      dashboard: 'Dashboard',
      dashboardOverview: 'Dashboard Overview',
      healthCheck: 'Health Check',
      devices: 'Devices',
      organisations: 'Organisations',
      organisationsOverview: 'Overview',
      groupTags: 'Group Tags',
      preToken: 'Pre Provisioning Token',
      logs: 'Logs'
    },
    login: {
      loginButton: 'Login',
      welcomeTitle: 'Welcome to easy4Enroll!',
      privacyStatement: 'Privacy Policy',
      termOfService: 'Terms of Service',
      faq: 'FAQ'
    },
    breadCrumb: {
      Home: 'Home',
      Dashboard: 'Dashboard',
      Devices: 'Devices'
    },
    button: {
      create: 'Create',
      register: 'Register',
      edit: 'Edit',
      close: 'Close',
      save: 'Save',
      SignInWithMicrosoft: 'Sign in with Microsoft',
      SignInWithGoogle: 'Sign in with Google',
      registerEasy4Enroll: 'Register easy4Enroll app',
      grantAdminConsent: 'Grant Application Permissions',
      bulkDeregisBtn: 'Bulk deregister',
      deRegister: 'De-register',
      inRepair: 'In-repair',
      isRepaired: 'Is-Repaired',
      reRegister: 'Reregister',
      captureDevices: '',
      invite: 'Invite',
      delete: 'Delete',
      disable: 'Disable',
      enable: 'Enable',
      add: 'Add',
      setup: 'Setup',
      upload: 'Upload',
      bulkDeleteByCSV: 'Bulk delete by CSV',
      cancel: 'Cancel',
      addUser: 'Add User',
      addCompany: 'Add Company',
      addGoogleDomain: 'Add Google Domain'
    },
    profileSetting: {
      signOut: 'Sign out',
      openSetting: 'Open setting',
      settingManagement: `Setting & management`
    },
    message: {
      authenticationFail: 'Authentication failed',
      noData: 'No data',
      approved: 'This account has been approved.',
      rejected: 'This account has been rejected.',
      existedUser: 'Existed user:',
      deleteUser: 'This user has been deleted successfully',
      disableAccount: 'This user has been disabled',
      importSuccess: `Import successfully`,
      importFailed: `Import failed`
    },
    pages: {
      device: {
        serialNumber: 'Serial Number',
        state: 'State',
        aadDeviceId: 'AAD Device ID',
        vendor: 'Vendor',
        model: 'Model',
        osSystem: 'Operating system',
        osVersion: 'OS version',
        groupTag: 'Group Tag',
        profile: 'Enrollment Profile',
        action: 'Action',
        organisation: 'Organisaiton',
        googleDeviceCreated: 'Google Device Created',
        overview: {
          pageTitle: 'List of Devices'
        },
        create: {
          pageTitle: 'Register a Device',
          serialNumber: 'Serial Number',
          productKey: 'Product Key',
          hardwareHash: 'Hardware Hash',
          hardwareModel: 'Hardware Model',
          domain: 'Domain',
          customerId: 'Customer ID',
          attestedDeviceId: 'Attested Device ID',
          externalID: 'External ID'
        },
        detail: {
          deviceOverview: 'Device Overview',
          deviceInformation: 'Device Information',
          deviceSessionInformation: 'Device Session Information'
        },
        status: 'Status',
        error_name: 'Error Description',
        register_status: 'Register status',
        preProvisioningToken: 'Pre Provisioning Token',
        preProvisionedDeviceId: 'Pre Provisioned Device Id',
        brandCode: 'Brand Code',
        putDeviceToRepair: 'Do you want to put this device to repair',
        reRegisterDevice: 'Do you want to Reregister this device',
        putDeviceToIsRepaired: 'Please make sure to image device when device is_repaired',
        azureTenant: 'Azure Tenant',
        googleDomain: 'Google Domain',
        deleteDevices: 'Do you want to deregister these devices?',
        deleteDevice: 'Do you want to deregister this device:',
        registerSession: {
          ipAddress: 'IP Address',
          complete: 'Completed',
          serialNumber: 'Serial Number',
          state: 'State',
          successCode: 'Success Code',
          createdAt: 'Created At',
          updatedAt: 'Updated At',
          message: 'Message',
          completed: 'Completed',
          organisation: 'Organisation'
        },
        downloadExample: 'Download example files ',
        here: 'here',
        toSeeTheFormat: ' to see the acceptable format.',
        delete: 'Delete',
        cancel: 'Cancel',
        download: 'Download Results',
        confirm: 'Confirm',
        type: 'Type YES to delete all the serial imported',
        totalRecognizedSerials: 'Total Recognized Serials: ',
        totalNotManaged: 'Total Serials Not Managed By TRC: ',
        totalNotRecognized: 'Total Not Recognized Serials: ',
        deleteAllNotManagedi: 'Also delete all the serials not managed by TRC',
        chooseFile: 'Choose file',
        noFileChosen: 'No file chosen',
        advOpt: 'Advanced Options'
      },
      organisation: {
        Id: 'ID',
        name: 'Name',
        school: 'School',
        tenantID: 'TenantID',
        groupTag: 'Group Tag',
        externalId: 'External ID',
        nameShort: 'Name Short',
        parentExternalId: 'Parent External',
        entityId: 'Entity ID',
        defaultGroupTag: 'Default Student GroupTag',
        defaultStaffGroupTag: 'Default Staff GroupTag',
        groupTagLoaner: 'Default GroupTag Loaner',
        contactSupport: 'Contact Sales Support to enable this option',
        contact: 'Contact',
        updateGroupTag: 'Update Group Tags',
        removeOrganisationFromTenant: 'Remove Organisation From Tenant',
        doYouWantToRemoveOrganisationFromTenant: 'Do you want to remove organisation from tenant',
        confirm: 'Confirm',
        cancel: 'Cancel',
        organisationInformation: 'Organisation Information',
        preprovisioningToken: 'Preprovisioning Token',
        devices: 'Devices',
        organisation: 'Organisation',
        updateToken: 'Update Provisioning Token',
        enrollTrigger: 'Enroll Trigger',
        repairFlow: 'Repair Flow',
        setDeviceName: 'Set Device Name',
        deviceNameConfig: 'School Device Name Configuration',
        sequenceNumber: {
          required: 'Device Number Padding is required.',
          integer: 'Device Number Padding must be an integer.',
          min: 'Device Number Padding must be at least 0.',
          max: 'Device Number Padding cannot be greater than 5.',
          name: 'Device Number Padding',
          type: 'Must be a number between 0 to 5.'
        },
        prefixName: {
          name: 'Device Prefix Name',
          required: 'Device prefix name is required.',
          max: 'Device prefix name cannot exceed 15 characters.',
          matches:
            'Device prefix name must be alphanumeric and may include an optional trailing hyphen.'
        },
        suffixSequence: {
          required: 'The suffix sequence is required.',
          numeric: 'The suffix sequence must be numeric.',
          padding: 'The suffix sequence must match the required padding length.',
          greaterOrEqual:
            'The suffix sequence must be greater than or equal to the current sequence.',
          combinedMax: 'The combined name (prefix and suffix) must not exceed 15 characters.'
        },
        deviceName: {
          prefix: 'The device name',
          tooLong: 'is over 15 characters',
          currentName: 'Current Suffix Sequence'
        },
        caution: {
          title: 'Caution: Changing the Sequence',
          body: 'Increasing the sequence number is irreversible and may impact the uniqueness of device names. Ensure that the new sequence number will not create duplicate names for existing devices.',
          tip: {
            text: 'Tip:',
            body: 'Review the current device names before making changes. If unsure, consult your system administrator.'
          }
        },
        example: 'Example',
        save: 'Save',
        prefixNameGuide:
          'When the feature is set on organisation level, it should alway have a prefix. Please provide the correct prefix name and sequence numbers'
      },
      tenant: {
        name: 'Name',
        defaultDomainName: 'Default Domain Name',
        initialDomainName: 'Initial Domain Name',
        tenantId: 'Tenant/Domain Id',
        active: 'Active',
        updatedAt: 'Updated At',
        hostedDomain: 'Hosted Domain',
        checkStatus: 'Check Status',
        removeTenant: 'Remove Tenant',
        actions: 'Actions',
        confirmRemoveTenant: 'Do you want to remove this Tenant?',
        includeUser: 'Include user',
        no: 'No',
        yes: 'Yes',
        cancel: 'Cancel',
        confirm: 'Confirm',
        registration_link: 'Registration Link',
        add: 'Add Google Domain',
        localAdmin: 'Tenant Admin Email',
        domain: 'Hosted Domain',
        token: 'ZTE Token',
        lang: 'Language',
        admin: 'Admin Email',
        save: 'Save',
        update: 'Update',
        customerId: 'Customer ID',
        updateCustomerId: 'Update Customer ID',
        howToObtainCustomerId: 'How to obtain Customer ID?',
        addSchoolToTenant: 'Add School to Tenant',
        inOtherTenant: 'in other tenants',
        filterSchool:
          'Filter only schools that are in the same (family) belonging to the same tree.',
        beforeDelete: 'Press Confirm to remove the token from this School/Domain',
        filterByEntity: 'Filter by company entity',
        enableSetDeviceName: 'Enable the set device name policy for this tenant'
      },
      healthCheck: {
        lastConnection: 'Last connection',
        applicationConnection: 'Application connection',
        permissions: 'Permissions',
        cname: 'Autopilot CNAME',
        customerId: 'Customer ID',
        zteToken: 'ZTE Token',
        customerIdFail: 'This Tenant does not have Customer ID',
        zteTokenFail: 'This Tenant does not have ZTE Token',
        upgrade: 'Upgrade',
        upgradePlus: 'Upgrade to Easy4EnrollPlus App',
        grantPermissions: 'Grant App Permission',
        newFeatures:
          'The new Plus App includes additional permissions required for advanced device management, such as:',
        stepsToUpgrade: 'To upgrade to the Plus App, follow these steps:',
        step1:
          'Click "Upgrade" to grant permissions for the new Plus App, including the additional required permission.',
        step2:
          '(Optional) After granting permissions, you can optionally remove the basic app to avoid conflicts. Click "How To Remove Basic App" for more details.',
        adminNote: 'Note: Admin privileges are required to complete this process.',
        removeBasicApp: 'How To Remove Basic App'
      },
      adminOverview: {
        registration: {
          id: 'Id',
          detail: 'Registration Detail',
          name: 'Name',
          email: 'Email',
          tenantDomain: 'Tenant Domain',
          organisationName: 'School Name',
          mobileNumber: 'Mobile Number',
          status: 'Status',
          createdAt: 'Created At',
          actions: 'Actions',
          rejectUser: 'Reject User',
          rejectConfirm: 'Reject this registration with email',
          invite: 'Invite',
          reject: 'Reject',
          cancel: 'Cancel',
          type: 'Type'
        },
        inviteAzureTenant: {
          tenantDomain: 'Tenant domain',
          schoolId: 'School Id',
          tenantAdminEmail: "Tenant's administrator email",
          language: 'Language',
          name: 'User name',
          applyToChild: 'Apply to child',
          userSchool: "User's school"
        },
        inviteGoogleDomain: {
          hostedDomain: 'Hosted domain',
          domainAdminEmail: "Google's administrator email",
          language: 'Language',
          schoolId: 'School Id',
          name: 'User name',
          applyToChild: 'Apply to child',
          userSchool: "User's school"
        },
        users: {
          id: 'Id',
          name: 'Name',
          email: 'Email',
          role: 'Role',
          status: 'Status',
          createdAt: 'Created At',
          registrationLink: 'Registration Link',
          add: 'Add User',
          selectTenant: "Select User's Tenant",
          cancel: 'Cancel',
          save: 'Save',
          language: 'Language',
          lastSignedIn: 'Last Signed In',
          ipAddress: 'IP Address',
          detail: {
            id: 'Id',
            name: 'Name',
            email: 'Email',
            role: 'Role',
            status: 'Status',
            createdAt: 'Created at',
            approve: 'Approve',
            reject: 'Reject',
            pending: 'Pending',
            active: 'Active',
            rejected: 'Rejected',
            invite: 'Invite',
            invited: 'Invited',
            disabled: 'Disabled',
            accepted: 'Accepted',
            organization: 'Organization',
            save: 'Save',
            userData: 'User data',
            phone: 'Phone',
            tenant: 'Azure Tenants',
            googleDomain: 'Google Domains',
            defaultDomainName: 'Default Domain Name',
            initialDomainName: 'Initial Domain Name',
            tenantId: 'Tenant/Domain Id',
            updatedAt: 'Updated At',
            hostedDomain: 'Hosted Domain',
            userInfo: 'User Information',
            gender: 'Gender',
            emailVerifyAt: 'Email verify at',
            organisation_name: 'Name of School'
          }
        },
        applyToChild: 'Add child organisations to the tenant above.',
        company: 'The company which the tenant belongs to.',
        companyName: 'Company Name'
      },
      overview: {
        company: {
          title: 'Company Overview',
          add: 'Add Company',
          search: 'Search by company name',
          name: 'Name',
          edit: 'Edit',
          users: 'Users',
          schools: 'Schools',
          tenants: 'Tenants'
        },
        role: {
          name: 'Name',
          guard_name: 'Guard name',
          permissions: 'Permissions',
          save: 'Save',
          create: 'Create',
          edit: 'Edit',
          createdAt: `Created at`,
          updatedAt: `Updated at`,
          connectedUsers: `Connected users`
        },
        roleDetail: {
          name: `Name`,
          guard_name: `Guard Name`,
          permissions: `Permissions`,
          save: `Save`,
          data_not_found: `Data not found`,
          connectedUsers: `Connected users`,
          id: `ID`,
          user: `User`,
          status: `Status`,
          search: `Search`,
          fullname: `Full name`,
          email: `Email`,
          team: `Team`,
          workbench: `Workbench`,
          enabled: 'Enabled',
          disabled: `Disabled`,
          state: `State`,
          pageName: `Role ID`
        },
        settings: {
          title: 'Settings',
          registrations: 'Registrations',
          enableStep4: 'Enable step 4 Make an appointment',
          appointmentLink: 'Appointment link',
          save: 'Save'
        },
        activities: {
          name: 'Name',
          account: 'Account Type',
          method: 'Method',
          description: 'Description',
          createdAt: 'Tracked at',
          userId: 'User Id',
          action: 'Action',
          activityLog: 'Activity Log',
          id: 'ID',
          pageName: `Activity Logs`,
          user: `User`,
          key: `Key`,
          original: `Original Data`,
          new: `New Data`,
          data: `Data`,
          model: `Model`,
          dataChanged: 'Data changed',
          userType: 'User Type',
          searchByDes: 'Search By Description'
        }
      },
      userRegister: {
        title: 'Welcome to Easy4enroll',
        greeting: 'Dear System Administrator,',
        useTheGoogleButton:
          "Please use the 'Login' button below to register for Google Zero-touch Enrollment (ZTE) and enter your ZTE token for Easy4enroll.",
        googleBodyContent1:
          'Great that you want to register for easy4Enroll and Google ZTE (Zero-Touch Enrollment).',
        googleBodyContent2:
          'To setup easy4Enroll, we have a few questions about your Admin contact details, the Google domain and we need your ZTE token.',
        googleBodyContent3:
          'When these questions are answered, we will setup our system to automatically enroll the new Chromebooks.',
        googleBodyContent4:
          "If you have any questions or if you have multiple OU's, simply contact",
        bodyContent1:
          'Thank you for your interest in connecting with Easy4enroll. This simple principle has great potential to save time and frustration for all of us. Easy4enroll seamlessly integrates with your Intune environment, enabling us (The Rent Company) to automatically register and remove systems under our management in your Intune environment.',
        bodyContent2:
          'To register for Easy4enroll, we have a few questions about your Intune environment. Once these questions are answered, we will initiate the registration process.',
        bodyContent3: 'If you have any questions, please feel free to contact us at ',
        note: 'NOTE: please use email that you can login to Microsoft Azure',
        googleNote: 'NOTE: please use email that you can login to Google Admin Console',
        zte: 'ZTE Token',
        pleaseCheck: 'NOTE: please check this ',
        forGetToken: ' for get pre-provisioning token.',
        forGetCid: ' for get pre-provisioning token.',
        zteError: 'ZTE Token is a required field.',
        customerError: 'Customer Id is a required field.',
        or: 'or',
        fullname: 'Fullname',
        email: 'Email',
        schoolName: 'Name of School',
        tenantDomain: 'Domain hostname',
        submit: 'Submit',
        mobileNumber: 'Mobile Number',
        language: 'Language',
        fullnameError: 'Fullname is a required field.',
        emailError: 'Email is a required field.',
        emailNotValid: 'Email is not a valid',
        schoolNameError: 'School name is a required field.',
        tenantDomainError: 'Tenant domain is a required field.',
        mobileNumberError: 'Mobile number is a required field.',
        mobileNumberInvalid: 'Mobile number is not valid',
        thankYouPage: {
          title: 'Thank you',
          thankYouForRegister: 'Thank you for registering your tenant domain with easy4Enroll',
          yourSubmission: 'Your submission will be reviewed by our administrators.',
          whenApproved:
            'When your submission is approved, you will receive an email with further instructions.'
        }
      }
    },
    validation: {
      emailRequired: 'Email is a required field.',
      emailInvalid: 'Email is an invalid format.',
      schoolAndEntityReuiredTogether:
        'Both school and Entity Tid are required together or should be nul',
      ggEmailRequired: 'Google domain admin email is required',
      domainRequired: 'Hosted domain is required',
      nameRequired: 'Name is required',
      tenantDomain: 'Tenant domain is required',
      orgRequired: 'Organisation is required',
      requiredOneSerialOrPKID: 'At least serial or pkid or both',
      noOrg: 'No valid organisations are available'
    }
  }
}
