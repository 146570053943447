import { Box, Divider, Grid, Link, Stack, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { LoadingButton } from '~/components/Button/LoadingButton'
import CustomModal from '~/components/Modal/CustomModal'
import { grantPermissionRedirect } from '~/utils/msal'
import howToRemoveApp from '~/assets/Easy4EnrollUnlinkBasicApp.pdf'

const GrantAppPermissionSection = ({ tenant, isLoading }) => {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)

  const handleGrantApp = useCallback(() => {
    grantPermissionRedirect(
      tenant.serviceTenant?.tenant_id,
      `${window.location.origin}/health-check`
    )
  }, [tenant.serviceTenant?.tenant_id])

  const handleUpgrade = useCallback(() => {
    if (tenant.can_upgrade_plus) {
      setOpen(true)
    } else {
      handleGrantApp()
    }
  }, [handleGrantApp, tenant.can_upgrade_plus])

  return (
    <>
      <LoadingButton
        loading={isLoading}
        onClick={handleUpgrade}
        sx={{ mt: 1, mr: 1 }}
        variant='contained'
      >
        {tenant.can_upgrade_plus ? t('pages.healthCheck.upgrade') : t('general.grantAppPermission')}
      </LoadingButton>

      <CustomModal open={open} onClose={() => setOpen(false)} style={{ width: 800 }}>
        <Typography variant='h5' sx={{ fontWeight: 'bold' }} gutterBottom>
          {t('pages.healthCheck.upgradePlus')}
        </Typography>
        <Divider />
        <Grid item mb={2} mt={2}>
          <Typography variant='body1'>{t('pages.healthCheck.newFeatures')}</Typography>
          <ul>
            <li>
              <strong>`DeviceManagementManagedDevices.PrivilegedOperations.All`</strong>:{' '}
              {t('pages.healthCheck.newFeatures')}
            </li>
          </ul>
          <Typography>{t('pages.healthCheck.stepsToUpgrade')}</Typography>
          <ol>
            <li>{t('pages.healthCheck.step1')}</li>
            <li>
              <Box>{t('pages.healthCheck.step2')}</Box>
            </li>
          </ol>
        </Grid>
        <Divider />
        <Stack
          sx={{ mt: 2 }}
          direction='row'
          justifyContent='space-between'
          alignItems='center'
          gap={1}
        >
          <Typography variant='body2' color='textSecondary'>
            {t('pages.healthCheck.adminNote')}
          </Typography>
          <Stack direction='row' spacing={1}>
            <LoadingButton variant='contained' type='submit'>
              <Link
                href={howToRemoveApp}
                target='_blank'
                style={{ color: '#fff', textDecoration: 'none' }}
              >
                {t('pages.healthCheck.removeBasicApp')}
              </Link>
            </LoadingButton>
            <LoadingButton variant='contained' type='submit' onClick={handleGrantApp}>
              {t('pages.healthCheck.upgrade')}
            </LoadingButton>
          </Stack>
        </Stack>
      </CustomModal>
    </>
  )
}

GrantAppPermissionSection.propTypes = {
  tenant: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired
}

export default GrantAppPermissionSection
